import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/subscriptions.action';
import { HttpErrorResponse } from '@angular/common/http';

export interface SubscriptionsState {
  subscribed: boolean;
  loading: boolean;
  loaded: boolean;
  error?: HttpErrorResponse;
}

export const initialState: SubscriptionsState = {
  subscribed: false,
  loading: false,
  loaded: false,
};

export const SubscriptionsReducer = createReducer(
  initialState,
  on(actions.Subscribe, (state: SubscriptionsState, { email }) => {
    return {
      ...state,
      loading: true,
      error: undefined,
    };
  }),
  on(actions.SubscribeSuccess, (state: SubscriptionsState, { subscription }) => {
    return {
      ...state,
      subscribed: true,
      loaded: true,
      loading: false,
    };
  }),
  on(actions.SubscribeFail, (state: SubscriptionsState, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  })
);

export const getSubscribed = (state: SubscriptionsState) => state.subscribed;
export const getSubscriptionsLoaded = (state: SubscriptionsState) => state.loaded;
export const getSubscriptionsLoading = (state: SubscriptionsState) => state.loading;
export const getSubscriptionsError = (state: SubscriptionsState) => state.error;
