<app-bg-wave
  [autoPlay]="true"
  [startPos]="'12s'"
  [bgColor]="'var(--bs-secondary)'"
  [strokeColor]="'transparent'"
  class="cta-bgwave"
></app-bg-wave>
<div class="bg-secondary">
  <section class="container">
    <header class="mb-2 mb-md-4 d-flex flex-column flex-md-row align-items-start justify-content-between align-items-md-center">
      <div>
        <span class="type-body-md text-light fw-bold w-100">
          {{ block.subtitle || ('content-block-initiativen-subtitel' | fromDictionary) }}
        </span>
        <h1 class="h2 text-primary">
          {{ block.title || ('content-block-initiativen-titel' | fromDictionary) }}
        </h1>
      </div>

      <a class="button button-link button--primary" [routerLink]="['/initiatieven']">
        <span>{{ 'block-initiatives-bekijk-alle-knoptekst' | fromDictionary }}</span>
        <app-icon anchor="icon-arrow-right"></app-icon>
      </a>
    </header>

    <main *ngIf="initiatives?.length">
      <!--<app-slider [cursorArrow]="false" [total]="events!.length" [config]="sliderConfig">
      <div class="col-12 col-xsm-6 col-sm-6 col-md-6 col-lg-4" *ngFor="let event of events">
        <app-event-card [inverted]="true" [event]="event"></app-event-card>
      </div>
    </app-slider>-->

      <app-slider-v2>
        <swiper-slide class="swiper-slide" *ngFor="let initiative of initiatives">
          <app-initiative-card class="swiper-slide__card" [initiative]="initiative"></app-initiative-card>
        </swiper-slide>
      </app-slider-v2>
    </main>
  </section>
</div>

<app-bg-wave
  class="cta-bgwave bg-wave--flipped"
  [autoPlay]="true"
  [startPos]="'12s'"
  [bgColor]="'var(--bs-secondary)'"
  [strokeColor]="'transparent'"
></app-bg-wave>
