import { ChangeDetectionStrategy, Component, effect, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { getSubscribedToNewsletter, getSubscriptionsError, getSubscriptionsLoading, Subscribe } from '../../store';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CoreModule } from 'src/app/core/core.module';
import { CommonModule } from '@angular/common';
import { AlertComponent } from 'src/app/core/components/alert/alert.component';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldControl, MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';

@Component({
  selector: 'app-newsletter-subscription-form',
  templateUrl: './newsletter-subscription-form.component.html',
  styleUrls: ['./newsletter-subscription-form.component.scss'],
  imports: [
    MatLegacyInputModule,
    MatLegacyFormFieldModule,
    AlertComponent,
    ReactiveFormsModule,
    CommonModule,
    DictionaryNgrxModule,
    DictionaryNgrxModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class NewsletterSubscriptionFormComponent {
  private store = inject(Store);
  private fb = inject(FormBuilder);

  subscribed = this.store.selectSignal(getSubscribedToNewsletter);
  subscribing = this.store.selectSignal(getSubscriptionsLoading);
  error = this.store.selectSignal(getSubscriptionsError);

  form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });

  subscribe() {
    if (this.form.invalid && !this.subscribing()) {
      return;
    }

    const email = this.form.value.email;

    this.store.dispatch(Subscribe({ email: email! }));
  }
}
