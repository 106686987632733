<aside class="action-bar">
  <menu class="container action-bar__inner">
    <li *ngIf="!isSignedIn">
      <a [routerLink]="['/account/inloggen']" class="button button-link button--primary">
        <app-icon anchor="icon-login"></app-icon>
        <span>Inloggen</span>
      </a>
    </li>

    <li *ngIf="isSignedIn" class="action-bar__item" [cdkTrapFocus]="showActionsSubnav">
      <button class="button button-link button--primary" (click)="showActionsSubnav = !showActionsSubnav">
        <ng-container *ngIf="!showActionsSubnav">
          <span>{{ profile.fullName }}</span>
          <app-icon anchor="icon-arrow-down"></app-icon>
        </ng-container>
        <a class="button button-link button--primary" *ngIf="showActionsSubnav">
          <span>Sluiten</span>
          <app-icon anchor="icon-arrow-up"></app-icon>
        </a>
      </button>
      <ul class="action-bar__subnav" [class.set--active]="showActionsSubnav">
        <ng-container *ngIf="showActionsSubnav">
          <li class="action-bar__subnav__item mb-3">
            <a class="button button-link button--primary button--xs" [routerLink]="'/profiel'" (click)="showActionsSubnav = false">
              <app-icon anchor="icon-user"></app-icon>
              <span>Mijn profiel</span>
            </a>
          </li>
          <li class="action-bar__subnav__item">
            <button class="button button-link button--primary button--xs" (click)="signOut(); showActionsSubnav = false">
              <app-icon anchor="icon-signout-outline"></app-icon>
              <span>Uitloggen</span>
            </button>
          </li>
        </ng-container>
      </ul>
    </li>
    <!--<li>
      <button class="button button-link button--primary">
        <app-icon anchor="icon-contrast"></app-icon>
        <span>Contrast</span>
      </button>
    </li>-->
  </menu>
</aside>

<div
  class="topbar"
  [cdkTrapFocus]="menuActive"
  [cdkTrapFocusAutoCapture]="menuActive"
  [class.is--open]="menuActive"
  [class.set--fixed]="fixed"
>
  <div class="topbar__bar" [ngClass]="theme">
    <div class="container topbar__bar__inner">
      <a
        [routerLink]="'/'"
        class="topbar__logo"
        (click)="closeOverlay.emit()"
        [attr.aria-label]="'logo-alt-tekst' | fromDictionary"
        [tabIndex]="menuActive ? -1 : 0"
      >
        <img [src]="logoUrl" [alt]="'logo-alt-tekst' | fromDictionary" />
      </a>

      <nav class="topbar__nav">
        <span class="sr-only">Hoofdmenu</span>

        <ul class="navbar-list">
          <li class="navbar-list__item" *ngFor="let page of menu">
            <a
              (click)="closeOverlay.emit()"
              [routerLink]="page.url"
              [routerLinkActive]="'is--current'"
              [routerLinkActiveOptions]="{ exact: isHomeUrl(page.url) }"
              [tabIndex]="menuActive ? -1 : 0"
            >
              {{ page.title }}
            </a>
          </li>

          <li class="navbar-list__item d-flex" *ngIf="themes.length">
            <button type="button" [matMenuTriggerFor]="menu" class="d-flex align-items-center gap-2">
              {{ 'thema-menu-label' | fromDictionary }}
              <app-icon anchor="icon-arrow-down"></app-icon>
            </button>

            <mat-menu #menu="matMenu" [class]="'dropdown-menu-panel'">
              <!--<button mat-menu-item>Item 1</button>-->

              <a
                mat-menu-item
                *ngFor="let theme of themes"
                [routerLink]="['/thema', theme.slug]"
                class="theme-list-item-link"
                [routerLinkActive]="'is--current'"
                role="menuitem"
              >
                <span class="chip chip--primary">
                  <fstr-icon [anchor]="theme.icon"></fstr-icon>
                </span>

                <span>{{ theme.title }}</span>
              </a>
              <!--<app-dropdown-menu [themes]="themes"></app-dropdown-menu>-->
            </mat-menu>
          </li>
        </ul>

        <ul class="topbar__actions">
          <li>
            <button class="button button--nav-cta" (click)="participate()">{{ 'participatie-cta-knoptekst' | fromDictionary }}</button>
          </li>
          <li *ngIf="!menuActive || searchActive">
            <button class="d-flex" (click)="toggleSearch()">
              <span class="sr-only" *ngIf="!searchActive">Zoekvenster openen</span>
              <span class="topbar-action-label text-primary fw-bold" *ngIf="searchActive">Zoekvenster sluiten</span>
              <app-icon *ngIf="!searchActive" anchor="icon-search" class="topbar__search icon-sm"></app-icon>
            </button>
          </li>
          <li *ngIf="!searchActive && menuActive">
            <button (click)="closeOverlay.emit()" class="topbar-action-label text-primary fw-bold">Menu sluiten</button>
          </li>
          <li>
            <button
              class="hamburger"
              [class.is--active]="menuActive"
              (click)="toggleOverlay.emit(); searchActive = false"
              [title]="menuActive ? 'Sluit menu' : 'Open menu'"
            >
              <span class="hamburger__lines"></span>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <div class="topbar__overlay" *ngIf="menuActive && !searchActive">
    <div class="container topbar__overlay__inner">
      <div class="row overlay">
        <div class="col-lg-7 overlay__main">
          <nav class="overlay__main__inner">
            <h2 class="sr-only">Uitgebreid menu</h2>
            <ul class="nav-list">
              <li class="nav-list__item mb-4 mb-md-6" *ngFor="let page of menu">
                <a
                  class="h5 font-secondary fw-bold"
                  (click)="closeOverlay.emit()"
                  [routerLink]="page.url"
                  [routerLinkActive]="'is--current'"
                  [routerLinkActiveOptions]="{ exact: isHomeUrl(page.url) }"
                >
                  {{ page.title }}
                </a>

                <ul class="nav-list__item__submenu sub-nav-list">
                  <li class="sub-nav-list__item" *ngFor="let subMenu of page.menuItems">
                    <a
                      class="type-body-md"
                      [routerLink]="subMenu.url"
                      (click)="closeOverlay.emit()"
                      [routerLinkActive]="'is--current'"
                      [routerLinkActiveOptions]="{ exact: isHomeUrl(subMenu.url) }"
                    >
                      {{ subMenu?.title }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>

            <app-actions-card
              class="mb-3 d-block"
              [image]="{ url: 'ambassadeur-afbeelding-url' | fromDictionary }"
              [altText]="'ambassadeur-afbeelding-alt-tekst' | fromDictionary"
              [text]="'ambassadeur-cta-tekst' | fromDictionary"
              [title]="'ambassadeur-cta-titel' | fromDictionary"
              [link]="'ambassadeur-cta-link' | fromDictionary"
            ></app-actions-card>
          </nav>
        </div>
        <div class="col-lg-5 bg-secondary overlay__sidebar">
          <!--[bgColor]="'var(--bs-secondary)'"-->
          <app-bg-wave
            class="overlay__sidebar__wave"
            [bgColor]="'var(--bs-secondary)'"
            [strokeColor]="'transparent'"
            *ngIf="menuActive"
          ></app-bg-wave>

          <div class="overlay__sidebar__inner">
            <h3 class="h5 font-secondary fw-bold mb-4">{{ 'thema-menu-titel' | fromDictionary }}</h3>
            <ul class="d-flex gap-2 flex-column">
              <li *ngFor="let theme of themes">
                <a
                  [routerLink]="['/thema', theme.slug]"
                  [routerLinkActive]="'is--current'"
                  class="button button--light button-with-chip theme-nav-button"
                >
                  <span class="chip chip--theme chip--primary">
                    <fstr-icon [anchor]="theme.icon"></fstr-icon>
                  </span>

                  <span>{{ theme.title }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="topbar__overlay" *ngIf="searchActive && menuActive">
    <div class="container">
      <h2 class="text-primary mt-5 mb-4">{{ 'zoekvenster-hulptekst' | fromDictionary }}</h2>
      <ng-content select="[slot=search-bar]"></ng-content>
      <ng-content select="[slot=search-results]"></ng-content>
    </div>
  </div>
</div>
