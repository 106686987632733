import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { IconComponent, IconModule } from '@teamfoster/sdk/icon';
import { CoreModule } from 'src/app/core/core.module';
import { Theme } from '../../models';

@Component({
  selector: 'app-theme-header',
  templateUrl: './theme-header.component.html',
  standalone: true,
  imports: [CoreModule, DictionaryNgrxModule, RouterLink, CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemeHeaderComponent {
  @Input({ required: true }) theme!: Theme;

  get quickLinks() {
    const blockTypeAnchors = ['block-stories', 'block-initiatives', 'block-events'];
    return this.theme?.contentBlocks
      ?.map((a, i) => ({ ...a, index: i }))
      .filter(block => blockTypeAnchors.includes(block.blockType) && block.content?.length);
  }
}
