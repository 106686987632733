<section class="block-default container" [class.container-size-xs]="!block.backgroundTheme">
  <div [ngClass]="(block.backgroundTheme ? 'section-inset-y' : '') + (' bg-' + block.backgroundTheme)">
    <main class="text-max-width-sm mx-auto">
      <div class="text-center" *ngIf="block.subtitle || block.title">
        <span *ngIf="block.subtitle" class="fw-bold text-secondary">{{ block.subtitle }}</span>
        <h1 class="h2 block-default__title" *ngIf="block.title">
          {{ block.title }}
          <app-icon anchor="icon-squiggle" class="heading-squiggle" *ngIf="block.title"></app-icon>
        </h1>
      </div>

      <div
        *ngIf="block.text"
        class="markdown-content mt-3"
        fstrDynamicContent
        [innerHTML]="block.text || '' | resizeHtmlImages | stripEmbed : cookiePreferences | safe"
      ></div>
      <div *ngIf="block.button?.url" class="d-flex justify-content-center">
        <a class="button block-default__button mt-4" [routerLink]="block.button?.url">
          {{ block.button?.text }}
        </a>
      </div>
    </main>
  </div>
</section>

<app-bg-wave
  *ngIf="block.backgroundTheme"
  class="bg-wave--flipped"
  [autoPlay]="true"
  [startPos]="'12s'"
  [bgColor]="'var(--bs-' + block.backgroundTheme + ')'"
  [strokeColor]="'transparent'"
></app-bg-wave>
