<ul class="content-blocks" *ngIf="cookiePreferences$ | async as cookies">
  <li
    class="content-blocks__item"
    *ngFor="let block of filteredBlocks; trackBy: trackByFn; let i = index"
    [ngSwitch]="block.blockType"
    [ngClass]="'type--' + block.blockType"
    [id]="'blok-' + i"
    [class.guide-border-top]="showLabels"
    [class.section-outset-y]="!hasBackground(block) && i !== 0"
  >
    <div class="guide-container" *ngIf="showLabels">
      <code class="guide-label mb-2 container">Bloktype: {{ block.blockType }}</code>
    </div>

    <app-mosaic-block class="container d-block" [block]="block" [theme]="theme" *ngSwitchCase="'block-inspiration'"></app-mosaic-block>
    <app-content-block-cta class="d-block" [block]="block" *ngSwitchCase="'block-cta'"></app-content-block-cta>
    <app-content-block-maps class="d-block" [block]="block" *ngSwitchCase="'block-stories-map'"></app-content-block-maps>
    <app-content-block-stories
      class="d-block block-overflow-x-hidden"
      [block]="block"
      [content]="block.content || []"
      *ngSwitchCase="'block-stories'"
    ></app-content-block-stories>
    <app-content-block-initiatives
      class="d-block block-overflow-x-hidden"
      [block]="block"
      [content]="block.content || []"
      *ngSwitchCase="'block-initiatives'"
    ></app-content-block-initiatives>
    <app-content-block-events
      class="d-block block-overflow-x-hidden"
      [block]="block"
      [content]="block.content || []"
      *ngSwitchCase="'block-events'"
    ></app-content-block-events>
    <app-content-block-default [cookiePreferences]="cookies" class="d-block" [block]="block" *ngSwitchDefault></app-content-block-default>
  </li>
</ul>
