import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { BaseContent } from 'src/app/content/models';

@Injectable({
  providedIn: 'root',
})
export class NewslettersService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  subscribe(email: string) {
    return this.http.post<any>(`${this.BASE_URL}api/newsletters/subscribe?email=${email}`, null);
  }
}
