import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromSubscriptions from '../reducers/subscriptions.reducer';

export const getSubscriptionsState = createSelector(
  fromFeature.getRootSubscriptionsState,
  (state: fromFeature.RootSubscriptionsState) => state.subscriptions
);

export const getSubscribedToNewsletter = createSelector(getSubscriptionsState, fromSubscriptions.getSubscribed);
export const getSubscriptionsLoaded = createSelector(getSubscriptionsState, fromSubscriptions.getSubscriptionsLoaded);
export const getSubscriptionsLoading = createSelector(getSubscriptionsState, fromSubscriptions.getSubscriptionsLoading);
export const getSubscriptionsError = createSelector(getSubscriptionsState, fromSubscriptions.getSubscriptionsError);
