import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  InjectionToken,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-bg-wave',
  templateUrl: './bg-wave.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BgWaveComponent implements OnInit, AfterViewInit {
  @Input() bgColor = 'var(--bs-secondary)';
  @Input() strokeColor = 'var(--bs-primary)';
  @Input() autoPlay = true;
  @Input() startPos = '0s';
  @Input() correctOffset = false;

  @ViewChild('bgwave') elBgWave: ElementRef<any> | undefined;

  constructor(@Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    if (!this.autoPlay && isPlatformBrowser(this.platformId)) {
      this.elBgWave?.nativeElement?.pauseAnimations();
    }
  }
}
