import * as fromSubscriptions from './subscriptions.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'Newsletters';

export interface RootSubscriptionsState {
  subscriptions: fromSubscriptions.SubscriptionsState;
}

export const reducers: ActionReducerMap<RootSubscriptionsState> = {
  subscriptions: fromSubscriptions.SubscriptionsReducer,
};

export const getRootSubscriptionsState = createFeatureSelector<RootSubscriptionsState>(featureKey);
export const metaReducers: MetaReducer<RootSubscriptionsState>[] = !environment.production ? [] : [];
