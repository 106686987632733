<svg
  class="bg-wave"
  [class.wave--correct-offset]="correctOffset"
  aria-hidden="true"
  width="100%"
  height="200px"
  viewBox="0 0 1920 200"
  preserveAspectRatio="none"
  fill="none"
  #bgwave
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    [attr.fill]="bgColor"
    id="WaveAnim"
    class="bg-wave__background"
    d="
      M0 67
      C 273,183
        822,-40
        1920.00,106

      V 359
      H 0
      V 67
      Z"
  >
    <animate
      repeatCount="indefinite"
      fill="url(#grad1)"
      attributeName="d"
      dur="15s"
      [attr.begin]="startPos"
      attributeType="XML"
      values="
        M0 77
        C 473,283
          822,-40
          1920,116

        V 359
        H 0
        V 67
        Z;

        M0 77
        C 473,-40
          1222,283
          1920,136

        V 359
        H 0
        V 67
        Z;

        M0 77
        C 973,260
          1722,-53
          1920,120

        V 359
        H 0
        V 67
        Z;

        M0 77
        C 473,283
          822,-40
          1920,116

        V 359
        H 0
        V 67
        Z
        "
    ></animate>
  </path>
  <path
    [attr.stroke]="strokeColor"
    strokeWidth="4"
    class="bg-wave__stroke"
    fill="none"
    d="
      M0 67
      C 273,183
        822,-40
        1920.00,106

      V 359
      H 0
      V 67
      Z"
  >
    <animate
      repeatCount="indefinite"
      fill="url(#grad1)"
      attributeName="d"
      dur="15s"
      begin="-.75s"
      attributeType="XML"
      values="
        M0 77
        C 473,283
          822,-40
          1920,116

        V 359
        H 0
        V 67
        Z;

        M0 77
        C 473,-40
          1222,283
          1920,136

        V 359
        H 0
        V 67
        Z;

        M0 77
        C 973,260
          1722,-53
          1920,120

        V 359
        H 0
        V 67
        Z;

        M0 77
        C 473,283
          822,-40
          1920,116

        V 359
        H 0
        V 67
        Z
        "
    ></animate>
  </path>
</svg>
