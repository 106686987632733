import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, of, tap } from 'rxjs';
import { NewslettersService } from '../../services';

import * as actions from '../actions';

@Injectable()
export class SubscriptionsEffects {
  constructor(
    private actions$: Actions,
    private service: NewslettersService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>
  ) {}

  Subscribe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.Subscribe),
      switchMap(({ email }) =>
        this.service.subscribe(email).pipe(
          map(subscription => actions.SubscribeSuccess({ subscription })),
          catchError(error => of(actions.SubscribeFail({ error })))
        )
      )
    );
  });
}
