import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  inject,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';

import { register, SwiperContainer } from 'swiper/element';
import {
  Autoplay,
  EffectCoverflow,
  EffectCreative,
  FreeMode,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Thumbs,
  A11y,
} from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';
register();

@Component({
  selector: 'app-slider-v2',
  templateUrl: './slider-v2.component.html',
  styleUrls: ['./slider-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [DictionaryNgrxModule, RouterLink],
})
export class SliderV2Component implements AfterViewInit {
  private platformId = inject(PLATFORM_ID);

  cd = inject(ChangeDetectorRef);

  @ViewChild('swipercontainer') swiperEl?: ElementRef<SwiperContainer>;

  ngAfterViewInit(): void {
    this.initSlider();
  }

  initSlider() {
    const that = this;

    const params: SwiperOptions = {
      modules: [Mousewheel, FreeMode, Pagination, Navigation, A11y],
      slidesPerView: 1.5,
      grabCursor: true,
      touchEventsTarget: 'container',
      spaceBetween: 17,
      freeMode: {
        momentumRatio: 0.5,
        momentumVelocityRatio: 0.5,
      },
      //navigation: {
      //  nextEl: '.swiper-button-next',
      //  prevEl: '.swiper-button-prev',
      //},
      //pagination: {
      //  type: 'progressbar',
      //  el: '.event-slider-progress',
      //},
      mousewheel: {
        forceToAxis: true,
      },
      preventClicks: true,
      preventClicksPropagation: true,
      touchMoveStopPropagation: false,
      edgeSwipeDetection: 'prevent', //will prevent system swipe-back navigation
      injectStyles: [
        `
					:host .swiper {
						overflow:inherit;
					}
          .swiper {
            display: flex;
          }
        `,
      ],
      breakpoints: {
        450: {
          slidesPerView: 1.5,
        },
        576: {
          slidesPerView: 'auto',
        },
      },
    };

    if (this.swiperEl?.nativeElement && isPlatformBrowser(this.platformId)) {
      Object.assign(this.swiperEl!.nativeElement, params);
      this.swiperEl!.nativeElement.initialize();
    }

    this.cd.detectChanges();
  }

  focusTo(element: string) {
    const el = document.querySelector(element);
    if (el && (el as HTMLElement)) {
      (el as HTMLElement).focus();
    }
  }
}
