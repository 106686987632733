import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { CookiePreferences, getCookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { map, Observable, of } from 'rxjs';
import { ContentBlock } from 'src/app/content-blocks/models';
import { getAllStories } from 'src/app/stories/store';
import { Theme } from 'src/app/themes/models';

@Component({
  selector: 'app-content-block-container',
  templateUrl: './content-block-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockContainerComponent implements OnInit {
  @Input() blocks: ContentBlock[] = [];
  @Input() showLabels: boolean = false;
  @Input() theme?: Theme;

  cookiePreferences$?: Observable<CookiePreferences>;
  /**
   *
   */

  store = inject(Store);
  //testStories = this.store.selectSignal(getAllStories);

  get filteredBlocks() {
    return this.blocks.filter(block => {
      const blocksWithContent = ['block-mosaic', 'block-events', 'block-initiatives', 'block-stories'];
      if (blocksWithContent.includes(block.blockType)) {
        return block.content?.length;
      }
      return true;
    });
  }

  ngOnInit(): void {
    this.cookiePreferences$ = this.store.select(getCookiePreferences).pipe(
      map(
        a =>
          a || {
            functional: false,
            analytical: false,
            marketing: false,
          }
      )
    );
  }

  trackByFn(index: number, block: ContentBlock) {
    return block.id;
  }

  hasBackground(block: ContentBlock) {
    //const blocksWithBackground = ['block-cta', 'block-initiatives'];

    //// backgrou
    ////if (block.blockType === 'default') {
    ////  return !!block.backgroundTheme;
    ////}

    //return blocksWithBackground.includes(block.blockType);
    return false;
  }
}
