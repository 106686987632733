<div class="card bg-light text-primary card--actions w-100 pb-3">
  <div class="w-100">
    <h2 class="h4">{{ 'nieuwsbrief-titel' | fromDictionary }}</h2>
    <ng-container *ngIf="!subscribed()">
      <p>{{ 'nieuwsbrief-tekst' | fromDictionary }}</p>
      <form [formGroup]="form" (ngSubmit)="subscribe()" class="newsletter-form">
        <mat-form-field appearance="fill" class="newsletter-form__input">
          <mat-label>{{ 'nieuwsbrief-email-label' | fromDictionary }}</mat-label>
          <input matInput formControlName="email" type="email" placeholder="E-mail" [disabled]="subscribing()" />

          <mat-error *ngIf="form.get('email')?.invalid">Dit is geen correct e-mailadres.</mat-error>
        </mat-form-field>
        <button type="submit" class="button button--primary newsletter-form__button" [disabled]="subscribing()">
          {{ 'nieuwsbrief-knoptekst' | fromDictionary }}
        </button>
      </form>
    </ng-container>

    <app-alert class="w-100 my-3 d-block" [status]="'success'" *ngIf="subscribed()">
      {{ 'nieuwsbrief-aanmelding-succes' | fromDictionary }}
    </app-alert>
    <app-alert class="w-100 mt-3 d-block" [status]="'danger'" *ngIf="error()">{{ error()?.error | fromDictionary }}</app-alert>
  </div>
</div>
